import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

const PostHeader = ({ content }) => {
  if (!content) return null
  return (
    <>
      <SEO 
        image={content.cover_image.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url={`https://bonsai.is/blog/${content._meta.uid}`}
      />
      <div className="full_banner full_banner_normal d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <h2 className="blog-heading">{RichText.asText(content.title)}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="full_image">
        <Img fluid={content.cover_imageSharp.childImageSharp.fluid} alt={content.cover_image.alt} />

        <div className="d-flex post-single-info align-items-start justify-content-between mt-md-3 mt-2">
          <p>{Intl.DateTimeFormat('en-US', { month: 'long', day:'numeric', year: 'numeric' }).format(new Date(content.date))}}</p>
          <p>{RichText.asText(content.read)}</p>
        </div>
      </div>
    </>
  )
}

const PostSlices = ({ content }) => {
  if (!content) return null
  return (
    <div className="container post-single-content">
      {content.body.map((slice, index) => {
        if(slice.type === "info_text"){
          return (
            <div className="post_info_text d-flex text-right">
              <p key={index} className="ml-auto">{RichText.asText(slice.primary.text)}</p>
            </div>
            )
          }
          if(slice.type === "heading"){
          return (
            <div className="post_heading">
              <h2 key={index}>{RichText.asText(slice.primary.heading)}</h2>
            </div>
          )
        }
        if(slice.type === "paragraph"){
          return (
            <div className="post_paragrpah">
              <p key={index}>{RichText.asText(slice.primary.paragraph)}</p>
            </div>
          )
        }
        if(slice.type === "image"){
          return (
            <div className="post_info_image" key={index}>
              <Img fluid={slice.primary.imageSharp.childImageSharp.fluid} alt={slice.primary.image.alt}/>
              <h3>{RichText.asText(slice.primary.image_tet)}</h3>
            </div>
          )
        }
        if(slice.type === "gif"){
          return (
            <div className="post_info_image" key={index}>
              <img src={slice.primary.gif.url} alt={slice.primary.gif.alt}/>
              <h3>{RichText.asText(slice.primary.image_tet)}</h3>
            </div>
          )
        }
        if(slice.type === "link"){
          return (
            <div className="post_info_link text-right">
              <a href={slice.primary.link.url} target="_blank" rel="noopener noreferrer" key={index}>
                <span>{RichText.asText(slice.primary.link_text)}</span>
                <img src="../../arrow.svg" className="arrow-left" alt="arrow"/>
              </a>
            </div>
          )
        }
        else{
          return null
        }
      })}
    </div>
  )
}

const PostAuthor = ({ content }) => {
  if (!content) return null
  return (
    <div className="team_area">
      <div className="container">
        <div className="row post-author">
          <div className="col-5 col-md-4">
            <Img fluid={content.author_imageSharp.childImageSharp.fluid} alt={content.author_image.alt}/>
          </div>
          <div className="col-7 col-md-8">
            <h3>WRITTEN BY</h3>
            <h2>{RichText.asText(content.author_name)}</h2>
            <p>{RichText.asText(content.author_about)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ({ data }) => {
  const content = data.prismic.allPosts?.edges[0]?.node
  if (!content) return null
  return (
    <Layout>
      <PostHeader content={content}/>
      <PostSlices content={content}/>
      <PostAuthor content={content}/>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery($uid: String){
    prismic {
      allPosts(uid: $uid) {
        edges {
          node {
            _meta {
                uid
              }
            cover_image
            date
            read
            seo_description
            seo_keywords
            seo_title
            title
            cover_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            body {
              ... on PRISMIC_PostBodyImage {
                type
                label
                primary {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  image_tet
                }
              }
              ... on PRISMIC_PostBodyGif {
                type
                label
                primary {
                  gif
                  gif_text
                }
              }
              ... on PRISMIC_PostBodyLink {
                type
                label
                primary {
                  link {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                  link_text
                }
              }
              ... on PRISMIC_PostBodyParagraph {
                type
                label
                primary {
                  paragraph
                }
              }
              ... on PRISMIC_PostBodyHeading {
                type
                label
                primary {
                  heading
                }
              }
              ... on PRISMIC_PostBodyInfo_text {
                type
                label
                primary {
                  text
                }
              }
            }
            author_about
            author_image
            author_name
            author_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`